import { useState, useEffect } from 'react'
import { Navigation } from './components/navigation'
import { Header } from './components/header'
import { Features } from './components/features'
import { Gallery } from './components/gallery'
// import { Testimonials } from './components/testimonials'
import { Footer } from './components/footer'
import SmoothScroll from 'smooth-scroll'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
 
  return (
    <div style={{
      backgroundColor: '#1c2a35'
    }}>
      <Navigation  />
      <Header  />
      <div style={{
        backgroundImage: 'url(img/background_rotate.png)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}>
        <Features />
        <Gallery />
      </div>
      <Footer />
    </div>
  )
}

export default App
