import ParticlesBg from "particles-bg";
import { useTranslation } from 'react-i18next';

export const Header = (props) => {
  const { t } = useTranslation();
  return (
    <header id='header'>
      <div className='intro' style={{
      backgroundColor: '#1c2a35'
    }}>
        <ParticlesBg type="cobweb" bg="#1c2a35" color="#ffd451" bg={{zIndex: 0, position:"absolute", top:0}} />
        <div className='overlay'>
          <div className='container'>
          
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                {t('Header.title')}
                  <span></span>
                </h1>
                <p>{t('Header.paragraph')}</p>
                <a
                  href='https://play.google.com/store/apps/details?id=com.proto.circuitsimulator&referrer=utm_source%3Dproto_website%26utm_medium%3Dbutton'
                  className='btn btn-custom btn-lg page-scroll' target="_blank">
                  {t('Header.download')}
                  <img className='btn-custom-logo btn-custom-logo:hover' src="img/gp_logo.svg"/>

                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}