import { useTranslation } from 'react-i18next';

export const Features = (props) => {
  const { t } = useTranslation();
  const features = t('Features', { returnObjects: true });

  return (
    <div id='features' className='text-center'>
      <div className='container' >
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2 style={{ color: 'white' }}>{t('Navigation.header1')}</h2>
        </div>
        <div className='row'>

        {features.map((feature) => (
          <div key={`${feature.title}-0`} className='col-xs-6 col-md-3' >
          {' '}
            <img className='fa' src={feature.img} align="center"></img>
  
          <h3 style={{ color: '#dde1e2' }}>{feature.title}</h3>
          <p style={{ color: '#90a4ae' }}>{feature.text}</p>
          </div>
        ))}
        </div>
      </div>
    </div>
  )
}
