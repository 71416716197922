import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import { useTranslation } from 'react-i18next';

export const Gallery = props => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const data = [
    { thumb: "img/gallery/s1.png", title: "" },
    { thumb: "img/gallery/s2.png", title: "" },
    { thumb: "img/gallery/s3.png", title: "" },
    { thumb: "img/gallery/s5.png", title: "" },
    { thumb: "img/gallery/s4.png", title: "" },
    { thumb: "img/gallery/s6.png", title: "" },

  ];

  const images = data.map(obj => obj.thumb.replace("-small", "-large"));

  const openImageViewer = useCallback(index => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const { t } = useTranslation();

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>               
             {t('Gallery.title')}
          </h2>
        </div>
        <div className="video-container">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/0OIjHXlec6w"
         title="YouTube video player" frameborder="0" 
         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {data.map(({ title, thumb }, index) => (
              <div key={index} onClick={() => openImageViewer(index)} className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item cursor">
                  <div className="hover-bg">
                    <div className="hover-text">
                    </div>
                    <img src={thumb} className="img-responsive" alt="Project Title" />{" "}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {isViewerOpen && (
            <ImageViewer
              src={images}
              backgroundStyle={{ zIndex: 99999 }}
              currentIndex={currentImage}
              onClose={closeImageViewer}
            />
          )}
        </div>
      </div>
    </div>
  );
};
